<template>
  <div class="career-container">
    <div style="padding:20px 0 10px 0;" class="center">
        <h4 class="approach-above-main-title">WORK WITH OUR TEAM</h4>
        <h1 class="approach-main-title medium-title">Career Opportunities</h1>
    </div>
    <div class="center">
      <div class="search-form-div">
        <v-card style="margin:10px; box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.1);">
          <v-card-text style="padding:30px;">
            <v-row>
              <v-col cols="12" sm="6">
                <h2 style="color:#000000;">Job Openings</h2>
              </v-col>
              <v-col cols="12" sm="6" style="text-align:right;">
                {{ totalJobs }} Jobs Available
              </v-col>
            </v-row><br/>

            <v-text-field 
              prepend-inner-icon="mdi-magnify" 
              placeholder="Search a Job" 
              v-model="search" 
              background-color="#F3F3F3"
              @input="searchJob()"
              flat
              solo 
              dense 
              hide-details
            >
            </v-text-field>

          </v-card-text>
          
        </v-card><br/>

        <v-row>
          <v-col cols="12" sm="6" md="6" lg="4" v-for="career in careers" :key="career.id">
            <v-card style="margin:10px;" class="career-card">
              <v-card-text style="padding:30px;">

                <h2 style="color:#2D2C2C;">{{ career.name }}</h2>
                <div style="color:#77C74E; margin:10px 0 7px 0;">{{ career.time[0] }} | {{ career.time[1] }}</div>
                <p class="career-description">{{ career.description }}</p>
                <v-btn color="#77C74E" dark depressed :to="'/careers/' + career.id">Apply Now</v-btn>
          
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-pagination
          v-if="totalItems>pageLimit"
          v-model="page"
          @input="changePage"
          :length="Math.ceil(totalItems/pageLimit)"
          color="#77C74E"
          style="margin-top:20px;"
        ></v-pagination>
      </div>

    </div><br/><br/>
  </div>
</template>

<script>
import Data from './../data'

export default {
  data() {
      return {
        page: 1,
        careers: Data.careers,
        pageLimit: 6,
        totalItems: Data.careers.length,
        search:'',
        length:0
      }
  },
  computed: {
    totalJobs() {
      return Data.careers.reduce((acc, item) => acc + item.openings, 0);
    }
  },
  created() {
    this.getData();
  },
  methods: {  
    changePage(page){
      this.careers = Data.careers.slice((page-1)* this.pageLimit, page* this.pageLimit) 
    },
    getData() {
      this.careers = Data.careers.slice((this.page-1)* this.pageLimit, this.page* this.pageLimit) 
    },
    searchJob(){
      this.careers = Data.careers.filter(item => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
}
</script>


<style scoped>
.career-container{
  background: #F3F3F3;
}
.approach-above-main-title{
  text-align:center;
  color:#ABABAC;
  letter-spacing: 3px;
  margin-top:10px;
}
.approach-main-title{
  text-align:center;
  font-weight: 600;
}
.search-form-div{
  width:80%;
  margin:auto;
}
.career-card{
  box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.1) !important;
}
.career-description{
  color:#2D2C2C;
  text-align: justify;
  text-justify: inter-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height:85px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* --------- Responsive ---------- */

@media only screen and (max-width: 400px) {
  .search-form-div{
    width:100%;
  }
}
@media only screen and (min-width: 400px) {
  .search-form-div{
    width:95%;
  }
}
@media only screen and (min-width: 600px) {
  .search-form-div{
    width:95%;
  }
}
@media only screen and (min-width: 768px) {
  .search-form-div{
    width:90%;
  }
}
@media only screen and (min-width: 880px) {
  .search-form-div{
    width:90%;
  }
}
@media only screen and (min-width: 992px) {
  .search-form-div{
    width:85%;
  }
}
@media only screen and (min-width: 1200px) {
  .search-form-div{
    width:85%;
  }
}
@media only screen and (min-width: 1300px) {
  .search-form-div{
    width:80%;
  }
}
@media only screen and (min-width: 1400px) {
  .search-form-div{
    width:80%;
  }
}
</style>

<template>
  <div class="it">
    <top-banner 
      title="Join Our Team"
      subtitle="Enhance and grow your career by applying to work with STS's experienced experts"
      :topimage="image"
    />

    <navbar/>
    <work />
    
  </div>
</template>

<script>
import image from "./../assets/img/Contact.png"
import TopBanner from '../components/TopBanner.vue'
import Navbar from '../components/Navbar.vue'
import work from '../components/work.vue'

export default {
  name: 'Careers',
  components: {
    TopBanner ,
    Navbar,
    work,
 
  },
  data() {
    return{
      image,
    }
  }
}
</script>